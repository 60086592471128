export function activateFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen(); // W3C spec
  } else if (typeof element.mozRequestFullScreen === "function") {
    element.mozRequestFullScreen(); // Firefox
  } else if (typeof element.webkitRequestFullscreen === "function") {
    element.webkitRequestFullscreen(); // Safari
  } else if (typeof element.msRequestFullscreen === "function") {
    element.msRequestFullscreen(); // IE/Edge
  }
}
export function deactivateFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (typeof document.mozCancelFullScreen === "function") {
    document.mozCancelFullScreen();
  } else if (typeof document.webkitExitFullscreen === "function") {
    document.webkitExitFullscreen();
  }
}